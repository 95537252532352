
import Vue from "vue";

import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";

interface IPayPlanActivitiesData {
  error: string;
  message: string;
}

export default Vue.extend({
  name: "payPlanActivitiesView",

  mixins: [ActivitiesMixin],
  data(): IPayPlanActivitiesData {
    return {
      error: "",
      message: ""
    };
  },
  computed: {
    queryOverride(): any {
      return {
        name__in: `DELETE_BULK_PAY_PLAN_CHANGE_REQUEST_SUCCESS,
          DELETE_BULK_PAY_PLAN_CHANGE_REQUEST_FAILURE,
          UPDATE_PAY_PLAN_CHANGE_REQUEST_SUCCESS,
          UPDATE_PAY_PLAN_CHANGE_REQUEST_FAILURE,
          ADD_PAY_PLAN_CHANGE_REQUEST_SUCCESS,
          ADD_PAY_PLAN_CHANGE_REQUEST_FAILURE,
          ADD_PAY_PLAN_CHANGE_REQUEST_SUCCESS,
          ADD_PAY_PLAN_CHANGE_REQUEST_FAILURE`.replace(/\s/g, ""),
        activityType: "PayPlanChangeRequestActivity"
      };
    }
  }
});
